import selectGetters from "@/store/templates/select/getters";
import { isNil, sortBy } from "lodash";
import { ITEMS } from "@/store/templates/select/getter-types";

export default {
  ...selectGetters,
  [ITEMS]: state => sortBy(Object.values(state.entities.entity), "name.nl_be"),
  rootCategories: state =>
    isNil(state.entities.category)
      ? []
      : Object.values(state.entities.category).filter(
          category => category.isRoot
        ),
  byIds: state => ids => ids.map(id => state.entities.category[id]),
  children: state => categoryId =>
    state.entities.category[categoryId].children.map(
      childCategoryId => state.entities.category[childCategoryId]
    ),
  leafs: state =>
    isNil(state.entities.category)
      ? []
      : Object.values(state.entities.category).filter(category =>
          isNil(category.children)
        )
};
