import selectActions from "@/store/templates/select/actions";
import api from "@/api";
import { INIT } from "@/store/templates/select/action-types";
import {
  SET_ENTITIES,
  SET_IS_INITIALIZED,
  SET_IS_LOADING
} from "../../templates/select/mutation-types";

const fetchCall = api.category.fetchAll;

export default {
  ...selectActions(),
  async [INIT]({ state, commit }) {
    // OVERRIDE THE DEFAULT
    commit(SET_IS_LOADING, true);
    const { entities, result } = await fetchCall({
      filters: { root: true },
      includes: ["children.children"]
    });
    commit(SET_ENTITIES, { entities, ids: result });
    if (!state.isInitialized) {
      commit(SET_IS_INITIALIZED, false);
    }
    commit(SET_IS_LOADING, false);
    return result;
  }
};
